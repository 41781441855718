export const FirebaseConfig = {
	"projectId": "tahiriluxuryservices-f5b2d",
	"appId": "1:335664010554:web:e98112171c537474b32c03",
	"databaseURL": "https://tahiriluxuryservices-f5b2d-default-rtdb.firebaseio.com",
	"storageBucket": "tahiriluxuryservices-f5b2d.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyA-J0dq170uhLYQf9SZqGtGKkbCZtT1Nrg",
	"authDomain": "tahiriluxuryservices-f5b2d.firebaseapp.com",
	"messagingSenderId": "335664010554",
	"measurementId": "G-S638QVPDEK"
};